<template>

  <div class="auth-wrapper auth-v2">

    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <b-img
          v-bind="mainProps"
          src="@/assets/images/logo/logo_grupovia.png"
          fluid
        />
      </b-link>
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="
                  w-100
                  d-lg-flex
                  align-items-center
                  justify-content-center
                  px-5
                  mt-3
                "
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bem vindo ao BackOffice
          </b-card-title>
          <b-card-text class="mb-2">
            Entre com seu endereço de e-mail empresarial
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    placeholder="Email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    @focus="error.show = false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Senha</label>
                  <b-link :to="{ name: 'forgot-password' }">
                    <small>Esqueceu sua Senha?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Senha"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      placeholder="*****"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      @focus="error.show = false"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div
                v-if="error.show"
                class="text-danger text-center mb-1 mt-1"
              >
                {{ error.text }}
              </div>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Entrar
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div>

</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import UserService from '@/service/user/user';

import { callLoading, removeDarkMode } from '@/service/utilities/index';

import 'animate.css';

export default {
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: '',
            password: '',
            userEmail: '',

            sideImg: require('@/assets/images/pages/login-v2.png'),
            // validation rulesimport store from '@/store/index'
            required,
            email,
            mainProps: { width: 270, height: 90, class: 'm2' },
            show_loading: false,
            error: {
                show: false,
                text: null,
            },
        };
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
        },
        imgUrl() {
            return this.sideImg;
        },
    },
    created() {
        removeDarkMode();
    },
    methods: {
        async login() {
            callLoading(true);

            const response = await UserService.signIn(this.userEmail, this.password);

            const { status } = response;

            if (status === 200) {
                window.location.href = '/';
                return;
            } if (status === 401) {
                this.error = {
                    text: 'Sua senha está incorreta. Confira-a.',
                    show: true,
                };
            } else if (status === 422) {
                this.error = {
                    text: 'O email inserido não pertence a uma conta. Verifique seu email e tente novamente.',
                    show: true,
                };
            } else { this.error.show = false; }

            callLoading(false);
        },
        async validationForm() {
            this.$refs.loginValidation.validate().then(success => {
                if (success) {
                    this.login();
                }
            });
        },
    },
};
</script>
    <style lang="scss" scoped>
    @import "@core/scss/vue/pages/page-auth.scss";
    </style>
